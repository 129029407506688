<template>
  <div class="page">
    <nav-top :index="3" ref='navTop' @cityLoaded="handleCityLoaded" @afterLogin="handleAfterLogin"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="#">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="/report">快速报备</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-info">
      <div class="info">
        <div class="title">
          <span>快速报备</span>
        </div>
        <div class="desc">
          <span>RAPID REPORTING</span>
        </div>
        <div class="more">
          <img src="/static/images/xbjt_ico.png" alt="">
        </div>
      </div>
    </div>
    <div class="report-info">
      <div class="all-report">
        <div class="title">
          <span>全部楼盘</span>
          <span>/ All properties</span>
        </div>
      </div>
      <div class="search-filtrate">
        <div class="search-info">
          <div class="search">
            <img src="/static/images/fdj_ico3.png" alt="">
            <input type="text" placeholder="输入项目名称进行搜索" v-model="this.keyword"
                   v-on:keyup.enter="handleKeywordInputEnter">
          </div>
          <div class="filtrate" v-loading="filterLoading">
            <el-dropdown>
              <div class="item">
                <span>{{ filter.area.index === null ? '区域' : filter.area.list[filter.area.index].name }}</span>
                <span class="arrow-down"></span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(item,index) in filter.area.list" :key="item.id"
                                    @click="handleFilterAreaItemClick(index)">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown>
              <div class="item">
                <span>{{ filter.type.index === null ? '户型' : filter.type.list[filter.type.index].name }}</span>
                <span class="arrow-down"></span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(item,index) in filter.type.list" :key="item.id"
                                    @click="handleFilterTypeItemClick(index)">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-popover placement="bottom" :width="400" trigger="click">
              <template #reference>
                <div class="item">
                  <span>{{ filterPriceText }}</span>
                  <span class="arrow-down"></span>
                </div>
              </template>
              <template #default>
                <el-tabs v-model="filter.price_tab" class="price-tabs" @tab-click="handlePriceTabClick">
                  <el-tab-pane label="单价" name="single">
                    <div class="price-content">
                      <el-input
                          v-model="filter.single_price.start_ready"
                          placeholder="最低价格"
                      />
                      <span class="suffix">元/平</span>
                      <span class="separate">至</span>
                      <el-input
                          v-model="filter.single_price.end_ready"
                          placeholder="最高价格"
                      />
                      <span class="suffix">元/平</span>
                    </div>
                    <el-button class="submit" @click="handleSinglePriceSubmit">提交</el-button>
                  </el-tab-pane>
                  <el-tab-pane label="总价" name="total">
                    <div class="price-content">
                      <el-input
                          v-model="filter.total_price.start_ready"
                          placeholder="最低价格"
                      />
                      <span class="suffix">万</span>
                      <span class="separate">至</span>
                      <el-input
                          v-model="filter.total_price.end_ready"
                          placeholder="最高价格"
                      />
                      <span class="suffix">万</span>
                    </div>
                    <el-button class="submit" @click="handleTotalPriceSubmit">提交</el-button>
                  </el-tab-pane>
                </el-tabs>
              </template>
            </el-popover>
            <el-dropdown>
              <div class="item">
                <span>{{
                    filter.furnish.index === null ? '房屋装修' : filter.furnish.list[filter.furnish.index].name
                  }}</span>
                <span class="arrow-down"></span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(item,index) in filter.furnish.list" :key="item.id"
                                    @click="handleFilterFurnishItemClick(index)">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown>
              <div class="item">
                <span>{{
                    filter.commission.index === null ? '佣金' : filter.commission.list[filter.commission.index].name
                  }}</span>
                <span class="arrow-down"></span>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="(item,index) in filter.commission.list" :key="item.id"
                                    @click="handleFilterCommissionItemClick(index)">{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div class="clear-all" @click="handleFilerClearClick">
          <span>清除全部条件</span>
        </div>
      </div>
      <div class="screen-info">
        <div class="screen-item">
          <div class="item" :class="{active:filter.sort === 'normal'}" @click="handleFilterSortChange('normal')">
            <span>默认排序</span>
          </div>
          <div class="item" :class="{active:filter.sort === 'price_asc'}" @click="handleFilterSortChange('price_asc')">
            <span>价格升序</span>
            <img src="/static/images/jtsc_ac.png" alt="">
          </div>
          <div class="item" :class="{active:filter.sort === 'price_desc'}"
               @click="handleFilterSortChange('price_desc')">
            <span>价格降序</span>
            <img src="/static/images/jtxj_ac.png" style="width: 22px;height: 15px;">
          </div>
        </div>
      </div>
      <div class="report-list" v-loading="reportLoading">
        <div class="list" v-for="(item,index) in report.list" :key="index" @click="handleReportClick(item.id)">
          <div class="pic">
            <img :src="item.image" alt="">
          </div>
          <div class="info">
            <div class="title">
              <span>{{ item.name }}</span>
            </div>
            <div class="desc">
              <span>[{{ item.city.name }} {{ item.area.name }}]{{ item.address }}</span>
            </div>
            <div class="desc">
              <span>户型：{{ item?.base_data?.types_for_sale }} 建筑面积：{{
                  item?.base_data?.area_start
                }}㎡-{{ item?.base_data?.area_end }}㎡</span>
            </div>
            <div class="tag-list">
              <div class="tag orange" v-if="item.base_data.primary_tag">
                <span>{{ item.base_data.primary_tag }}</span>
              </div>
              <div class="tag blue">
                <span v-if="item.base_data.product_status_id === 1">全城热销中</span>
                <span v-if="item.base_data.product_status_id === 2">火爆加推</span>
                <span v-if="item.base_data.product_status_id === 3">预售进行中</span>
                <span v-if="item.base_data.product_status_id === 4">待售</span>
              </div>
              <div class="tag" v-for="(secondary_tag,secondary_tag_index) in item.base_data.secondary_tags"
                   :key="secondary_tag_index">
                <span>{{ secondary_tag.tag }}</span>
              </div>
            </div>
          </div>
          <div class="price-btn">
            <div class="price-info">
              <div class="tit">
                <span>均价</span>
              </div>
              <div class="price">
                <span>{{ item.base_data.price_pending == 1 ? '待定' : item.base_data.price_start }}</span>
              </div>
              <div class="tit" v-show="item.base_data.price_pending != 1">
                <span>元/㎡</span>
              </div>
            </div>
            <div class="btn" :class="{disable:!item.can_report}" @click.stop="handleProjectReportClick(item)">
              <span>快速报备</span>
            </div>
          </div>
        </div>
      </div>
      <div class="paging-info">
        <div class="pagination">
          <div class="num" :class="{active:item == report.page.current}" v-for="item in report.page.last" :key="item"
               @click="handlePageClick(item)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="paging">
          <div class="cut" @click="handlePagePrevClick" v-show="this.report.page.current == 1">
            <span class="left"></span>
          </div>
          <div class="cut" @click="handlePageNextClick" v-show="this.report.page.current == this.report.page.last">
            <span class="right"></span>
          </div>
        </div>
      </div>
    </div>
    <fast-report v-model:show="report_show" :project_id="project_id" @afterSubmit="handleAfterSubmit"></fast-report>
    <footer-bar></footer-bar>
    <report-result v-model:show="report_result_show" v-model:report-type="report_result_type"
                   @close="handleReportResultClose"></report-result>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'
import FastReport from '../../components/FastReport/FastReport'
import ReportResult from '../../components/ReportResult/ReportResult'

export default {
  name: 'report',
  components: {FooterBar, NavTop, FastReport, ReportResult},
  data() {
    return {
      filterLoading: true,
      filter: {
        area: {
          index: null,
          list: []
        },
        type: {
          index: null,
          list: []
        },
        furnish: {
          index: null,
          list: []
        },
        commission: {
          index: null,
          list: []
        },
        single_price: {
          start: '',
          start_ready: '',
          end: '',
          end_ready: '',
        },
        total_price: {
          start: '',
          start_ready: '',
          end: '',
          end_ready: '',
        },
        price_tab: 'single',
        sort: 'normal'
      },
      keyword: '',
      reportLoading: true,
      report: {
        page: {
          current: 1,
          last: 1
        },
        list: []
      },
      project_id: null,
      report_show: false,
      report_result_show: false,
      report_result_type: 'success',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.fetchFilter()
      this.fetchData()
    },
    async fetchFilter() {
      try {
        let res = await Promise.all([
          new Promise((resolve, reject) => {
            this.http({
              url: '/api/report/projectArea',
              method: 'GET'
            }).then(res => resolve(res.data)).catch(err => reject(err))
          }),
          new Promise((resolve, reject) => {
            this.http({
              url: '/api/report/projectType',
              method: 'GET'
            }).then(res => resolve(res.data)).catch(err => reject(err))
          }),
          new Promise((resolve, reject) => {
            this.http({
              url: '/api/report/projectFurnish',
              method: 'GET'
            }).then(res => resolve(res.data)).catch(err => reject(err))
          }),
          new Promise((resolve, reject) => {
            this.http({
              url: '/api/report/projectCommission',
              method: 'GET'
            }).then(res => resolve(res.data)).catch(err => reject(err))
          })
        ])

        this.filter['area'].list = res[0]
        this.filter['type'].list = res[1]
        this.filter['furnish'].list = res[2]
        this.filter['commission'].list = res[3]
      } catch (e) {
        console.log(e)
      } finally {
        this.filterLoading = false
      }
    },
    async fetchData() {
      try {
        this.reportLoading = true

        let res = await this.http({
          url: '/api/report/project',
          method: 'GET',
          params: {
            keyword: this.keyword,
            area_id: this.filter.area.list[this.filter.area.index]?.id || null,
            type_id: this.filter.type.list[this.filter.type.index]?.id || null,
            furnish_id: this.filter.furnish.list[this.filter.furnish.index]?.id || null,
            commission_id: this.filter.commission.list[this.filter.commission.index]?.id || null,
            sign_price_start: this.filter.single_price.start,
            sign_price_end: this.filter.single_price.sign_price_end,
            total_price_start: this.filter.total_price.start,
            total_price_end: this.filter.total_price.sign_price_end,
            sort: this.filter.sort
          }
        })

        this.report.page.current = res.data.page.current
        this.report.page.last = res.data.page.last

        let memberInfo = JSON.parse(localStorage.getItem('MEMBER') || '{}')

        for (let index in res.data.list) {
          res.data.list[index].base_data.primary_tag = JSON.parse(res.data.list[index].base_data.primary_tags)[0]?.tag || null
          res.data.list[index].base_data.secondary_tags = JSON.parse(res.data.list[index].base_data.secondary_tags)
          if (memberInfo.can_receptionist !== undefined && !memberInfo.can_receptionist) {
            res.data.list[index].can_report = 0
          }
        }
        this.report.list = res.data.list
      } catch (e) {
        console.log(e)
      } finally {
        this.reportLoading = false
      }
    },
    handleAfterLogin() {
      let memberInfo = JSON.parse(localStorage.getItem('MEMBER') || '{}')
      if (memberInfo.can_receptionist !== undefined && !memberInfo.can_receptionist) {
        for (let index in this.report.list) {
          this.report.list[index].can_report = 0
        }
      }
    },
    handleFilterAreaItemClick(index) {
      this.filter.area.index = index
      this.report.page.current = 1
      this.fetchData()
    },
    handleFilterTypeItemClick(index) {
      this.filter.type.index = index
      this.report.page.current = 1
      this.fetchData()
    },
    handleFilterFurnishItemClick(index) {
      this.filter.furnish.index = index
      this.report.page.current = 1
      this.fetchData()
    },
    handleFilterCommissionItemClick(index) {
      this.filter.commission.index = index
      this.report.page.current = 1
      this.fetchData()
    },
    handleFilerClearClick() {
      this.filter.area.index = null
      this.filter.type.index = null
      this.filter.furnish.index = null
      this.filter.commission.index = null
      this.keyword = ''
      this.filter.single_price.start = ''
      this.filter.single_price.start_ready = ''
      this.filter.single_price.end = ''
      this.filter.single_price.end_ready = ''
      this.filter.total_price.start = ''
      this.filter.total_price.start_ready = ''
      this.filter.total_price.end = ''
      this.filter.total_price.end_ready = ''
      this.filter.sort = 'normal'
      this.report.page.current = 1
      this.fetchData()
    },
    handleFilterSortChange(sort) {
      this.filter.sort = sort
      this.report.page.current = 1
      this.fetchData()
    },
    handlePageClick(page) {
      this.report.page.current = page
      this.fetchData()
    },
    handlePagePrevClick() {
      this.report.page.current--
      this.fetchData()
    },
    handlePageNextClick() {
      this.report.page.current++
      this.fetchData()
    },
    handlePriceTabClick(e) {
      console.log(e)
    },
    handleSinglePriceSubmit() {
      this.filter.single_price.start = this.filter.single_price.start_ready
      this.filter.single_price.end = this.filter.single_price.end_ready
      this.filter.total_price.start = ''
      this.filter.total_price.end = ''
      this.report.page.current = 1
      this.fetchData()
    },
    handleTotalPriceSubmit() {
      this.filter.total_price.start = this.filter.total_price.start_ready
      this.filter.total_price.end = this.filter.total_price.end_ready
      this.filter.single_price.start = ''
      this.filter.single_price.end = ''
      this.report.page.current = 1
      this.fetchData()
    },
    handleKeywordInputEnter() {
      this.report.page.current = 1
      this.fetchData()
    },
    handleProjectReportClick(item) {
      if (!item.can_report) {
        return
      }

      let member_id = localStorage.getItem('MEMBER_ID')

      if (!member_id) {
        this.$refs['navTop'].login()
        return
      }

      this.project_id = item.id
      this.report_show = true
    },
    handleAfterSubmit(e) {
      if (e.code === 0) {
        this.report_result_type = 'success'
        this.report_result_show = true
      } else {
        this.report_result_type = 'error'
        this.report_result_show = true
      }
    },
    handleReportResultClose() {
      this.report_show = false
    },
    handleCityLoaded() {
      this.report.page.current = 1
      this.fetchData()
    },
    handleReportClick(id) {
      this.$router.push({
        path: '/HousesHome',
        query: {
          id: id,
        }
      })
    }
  },
  computed: {
    filterPriceText() {
      if (this.filter.single_price.start !== '' || this.filter.single_price.end !== '') {
        if (this.filter.single_price.start !== '' && this.filter.single_price.end !== '') {
          return `单价 ${this.filter.single_price.start} 至 ${this.filter.single_price.end} 元/平`
        }
        if (this.filter.single_price.start !== '') {
          return `单价 自 ${this.filter.single_price.start} 元/平`
        }
        if (this.filter.single_price.end !== '') {
          return `单价 至 ${this.filter.single_price.end} 元/平`
        }
      }

      if (this.filter.total_price.start !== '' || this.filter.total_price.end !== '') {
        if (this.filter.total_price.start !== '' && this.filter.total_price.end !== '') {
          return `总价 ${this.filter.total_price.start} 至 ${this.filter.total_price.end} 万`
        }
        if (this.filter.total_price.start !== '') {
          return `总价 自 ${this.filter.total_price.start} 万`
        }
        if (this.filter.total_price.end !== '') {
          return `总价 至 ${this.filter.total_price.end} 万`
        }
      }
      return '价格'
    }
  }
}
</script>

<style scoped lang="scss">
@import "report.scss";
</style>
